import React, { useState } from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import {
  Container,
  SectionPage,
  SectionTitle,
  SectionDescription,
} from "../Section"
import SecondaryButton from "../Button/SecondaryButton"

const ListCityState = styled.div`
  display:flex;
  flex-wrap:wrap;
  margin:0 -5px 30px -5px;
 
`
const ListItemCityState = styled.div`
  position:relative;
  width:100%;
  padding:5px 5px 5px 15px;
  margin:0;
  &:before{
    content: '';
    position: absolute;
    left: 0;
    width: 6px;
    height: 6px;
    background-color: #516c7c;
    border-radius: 50%;
    top: 50%;
    transform: translateY(-50%);
  }

  & .text, & a.text {
    display:inline-block;
    color: #eee;
    font-size:16px;
    line-height:26px;
  }
  & a.text{
    color: #eee;
    &:hover, &:focus{
      color:#ed602b;
    }
  }
  flex: 0 0 50%;
  max-width: 50%;

  @media (min-width: 576px) {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }

  @media (min-width: 992px) {
    flex: 0 0 25%;
    max-width: 25%;
  }

  @media (min-width: 1200px) {
    flex: 0 0 20%;
    max-width: 20%;
  }
  
`

export const ListCityAction = styled.div` 
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
`

const Map = ({
  stateName,
  mapImage,
  cities,
  heading,
  subheading,
  jsonCities,
}) => {
  const [limit, setlimit] = useState(30)

  if (!stateName) return null
  if (!mapImage) return null

  if (!!cities && cities.length > 0) {
    return (
      <SectionPage
        ept="80px"
        epb="80px"
        xpt="40px"
        xpb="40px"
        pt="30px"
        pb="30px"
        bg="#1E4156"
      >
        <Container maxWidth="1200px">
          <SectionTitle color="#fff">Cities We Serve In <strong>{stateName}</strong></SectionTitle>
          <SectionDescription textAlign="center" color="#fff">
            <p>We cover all locations throughout the state of{" "} <strong>{stateName}</strong>, including:</p>
          </SectionDescription>
          <ListCityState>
            {!!cities &&
              cities.length > 0 &&
              cities.map((city, i) => (
                <ListItemCityState key={i}>
                  <Link to={`${city.url}/`} className="text">{city.cityName}</Link>
                </ListItemCityState>
              ))}
          </ListCityState>
        </Container>
      </SectionPage>
    )
  }

  if (!!jsonCities && jsonCities.length > 0) {
    return (
      <SectionPage
        ept="80px"
        epb="80px"
        xpt="40px"
        xpb="40px"
        pt="30px"
        pb="30px"
        bg="#1E4156"
      >
        <Container maxWidth="1200px">
          <SectionTitle color="#fff"> 
          Cities We Serve In <strong>{stateName}</strong>
            {/* {heading} */}
          </SectionTitle>
          <SectionDescription textAlign="center" color="#fff">
            <p>
              We cover all locations throughout the state of{" "}
              <strong>{stateName}</strong>, including:
            </p>
            {/* <p>{subheading}</p> */}
          </SectionDescription>
          <ListCityState>
            {!!jsonCities &&
              jsonCities.length > 0 &&
              jsonCities
                .slice(0, limit)
                .map((cc, j) => <ListItemCityState key={j}><span className="text"> {cc.content}</span></ListItemCityState>)}
          </ListCityState>
          {limit < jsonCities.length && (
            <ListCityAction>
              <button type="button" onClick={() => setlimit(limit + 30)}>
                <SecondaryButton text="Load More" />
              </button>
            </ListCityAction>
          )}
        </Container>
      </SectionPage>
    )
  }

  return null
}

export default Map
